import { LANGUAGES, LOCAL_KEY } from '@/config';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { Storage } from './storage';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(localizedFormat);

export const getUnixTimestamp = (date: string | Date): number => {
  const inputDate = dayjs(date);
  const unixTimestamp = inputDate.valueOf() / 1000;
  return unixTimestamp;
};

export const getFormattedDate = (
  date_build: { day: number | null; month: number | null; year: number },
  format = 'DD MMMM YYYY'
) => {
  const lang = Storage.local.getRaw<LANGUAGES>(LOCAL_KEY.CURRENT_LOCALE) ?? LANGUAGES.PORTUGUESE;

  if (date_build.day === null && date_build.month === null && date_build.year === null) {
    return '';
  }

  let adjustedFormat = format;
  if (date_build.day === null && date_build.month === null) {
    adjustedFormat = 'YYYY';
  } else if (date_build.day === null) {
    adjustedFormat = 'MMMM YYYY';
  }

  const day = date_build.day ?? 1;
  const month = date_build.month ?? 1;

  const parsedDate = new Date(date_build.year, month - 1, day);

  const formatted = dayjs(parsedDate).locale(lang).format(adjustedFormat);

  return formatted;
};