import { envVars } from '@/config';
import type { IGetObjectByIdReq, IGetObjectByIdRes, IGetObjectsReq, IGetObjectsRes } from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';

export const objectApi = createApi({
  reducerPath: 'objectApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['objects'],
  endpoints: (builder) => ({
    getObjects: builder.query<IGetObjectsRes['data'], IGetObjectsReq & { filter_screen?: string }>({
      query: ({ locale, filter_screen }) => {
        const params = new URLSearchParams();
        if (locale) params.append('locale', locale);
        if (filter_screen) params.append('filter_screen', filter_screen);

        return {
          url: `/history/objects?${params.toString()}`,
          isProtected: false,
          forceMock: false,
        };
      },
      transformResponse: (response: IGetObjectsRes) => response.data ?? {},
    }),
    getObjectById: builder.query<IGetObjectByIdRes['data']['object'], IGetObjectByIdReq>({
      query: ({ id }) => ({
        url: `/history/object/${id}`,
        isProtected: false,
        forceMock: false,
      }),
      transformResponse: (response: IGetObjectByIdRes) => response.data?.object ?? {},
    }),
  }),
});

export const { useGetObjectsQuery, useGetObjectByIdQuery } = objectApi;
