const ROUTES = {
  MAIN: '/',
  HOME: '/',
  LOGIN: '/login',
  OBJECT: {
    MAIN: '/:screen_id',
    VIEW: '/:screen_id/object/:id',
  },
  ROBOT: {
    list: '/robots',
    view: '/robot/:id',
  },
};

export { ROUTES };
