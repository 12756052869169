import { ROUTES } from '@/config';
import { ObjectCard, ObjectCardSkeleton } from '@/core/blocks';
import { Carousel, CarouselContent, CarouselDots, CarouselItem } from '@/core/ui';
import { API } from '@/store/api';
import type { IObject } from '@/types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

export const ObjectList = () => {
  const { i18n } = useTranslation();
  const { screen_id } = useParams();

  const data = API.objectApi.useGetObjectsQuery(
    { locale: i18n.language, filter_screen: screen_id },
    {
      selectFromResult: ({ data }) => ({
        objects: data?.objects,
      }),
      skip: !i18n.language || !screen_id,
    },
  );

  const { data: configData } = API.oneCoreApi.useGetOneCoreConfigQuery();

  const [sendCommand] = API.oneCoreApi.useSendCommandMutation();

  const handleObjectClick = async (item: IObject) => {
    if (!configData?.data?.settings) {
      console.error('Configuration data is not available.');
      return;
    }

    try {
      const { IP_ADDRESS, TOKEN } = configData.data.settings;

      await sendCommand({
        app: 'objects',
        address: IP_ADDRESS ?? '192.168.0.1',
        key: TOKEN,
        options: {
          command: 'setChannelValue',
          channel: item.shelly.channel ?? 0,
          value: 1,
        },
        devices: [item.shelly.device_id],
      }).unwrap();

      console.log('Command sent successfully');
    } catch (error) {
      console.error('Failed to send command:', error);
    }
  };

  if (!data?.objects) {
    return (
      <div className='flex flex-row space-x-[50px] pt-[50px] pb-[70px] justify-center items-center'>
        {Array.from({ length: 4 }).map((_, index) => (
          <ObjectCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (data.objects.length < 5) {
    return (
      <div className='flex w-full space-x-[50px] justify-center items-center pt-[50px] pb-[70px]'>
        {data.objects.map((item) => (
          <Link
            key={item.id}
            to={`${ROUTES.OBJECT.VIEW.replace(':screen_id', screen_id ?? '').replace(':id', item.id.toString())}`}
            className='flex justify-center items-center'
            onClick={() => handleObjectClick(item)}
          >
            <ObjectCard name={item.name} url={item.image.path} />
          </Link>
        ))}
      </div>
    );
  }

  return (
    <div className='flex w-full justify-center items-center'>
      <Carousel
        opts={{
          align: 'center',
          startIndex: 1,
          dragFree: true,
        }}
        className='w-full'
      >
        <CarouselContent wrapperClass='px-20 py-12'>
          {data.objects.map((item) => (
            <CarouselItem key={item.id} className='basis-[25%]'>
              <Link
                to={`${ROUTES.OBJECT.VIEW.replace(':screen_id', screen_id ?? '').replace(':id', item.id.toString())}`}
                className='flex justify-center items-center'
                onClick={() => handleObjectClick(item)}
              >
                <ObjectCard name={item.name} url={item.image.path} />
              </Link>
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselDots className='' />
      </Carousel>
    </div>
  );
};
